import BasePage from '@/found/components/table_page';
import Form from '../../components/process_approve/approve.vue';
import Print from '../dealt/print';
import request from '../../../../utils/request';
import ChangeForm from './form';
import selectTemplate from './form/select_template.vue';
import delegateForm from './form/delegate_form.vue';

export default {
  extends: BasePage,
  components: {
    Form,
    ChangeForm,
    selectTemplate,
    delegateForm,
  },
  data() {
    return {};
  },
  created() {
    this.getConfigList('process_center_process_instance_main_table');
  },
  methods: {
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'view') {
        this.modalConfig.title = '查看';
        this.modalConfig.type = 'EmptyDrawer';

        this.formConfig = {
          code,
          row,
        };
        this.openModal();
      } else if (code === 'print') {
        console.log(row);
        request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'print_process_key' }).then((res) => {
          if (res.success) {
            for (const item of res.result) {
              let processKey1 = null;
              const processKeyList = item.process_key.split(',');
              processKey1 = processKeyList.find((v) => v === row.processKey);

              // if (item.process_key.indexOf(row.processKey) >= 0) {
              if (processKey1) {
                new Print({
                  id: row.businessId,
                  logId: row.processInstanceId,
                  type: item.dictCode,
                  rowId: row.id,
                  businessNo: row.businessNo,
                  processKey: row.processKey,
                }).print();
                break;
              }
            }
          }
        });
      } else if (code === 'delegate') {
        if (this.selectRow.length > 0) {
          if (this.selectRow.length === 1) {
            // this.modalConfig.title = '委派';
            // this.modalConfig.width = '800px';
            // this.modalConfig.showFooter = true;
            // this.modalConfig.buttons = {
            //   sure: true,
            //   close: true,
            // };
            // this.formName = 'selectTemplate';
            // this.modalConfig.type = 'Modal';
            // this.openModal();
            this.formName = 'ChangeForm';
            this.modalConfig = {
              title: '委派',
              width: '450px',
              height: '200px',
            };
            this.formConfig = {
              ...this.formConfig,
              code: val.code,
              row,
            };
            this.openModal();
          } else {
            this.$message('最多只能选择一条数据操作');
          }
        } else {
          this.$message('请最少选择一条数据操作');
        }
      }
    },
    onModalOk(val) {
      if (this.formName === 'selectTemplate') {
        const selectTemp = this.$refs.modalForm.$refs.xTable.getRadioRecord();
        if (selectTemp) {
          this.modalConfig.title = '委派';
          this.formName = 'delegateForm';
          this.modalConfig.showFooter = true;
          this.modalConfig.buttons = {
            sure: true,
            close: true,
          };
          this.formConfig = selectTemp;
          this.formConfig.templateCode = selectTemp.code;
          this.modalConfig.width = '800px';
          // this.modalConfig.visible = false;
          this.modalConfig.type = 'Modal';
          this.openModal();
        } else {
          this.$message.error('请选择一条数据');
        }
      }
    },
    // 关闭弹框
    closeModal() {
      const formData = this.$refs.modalForm?.fApi?.formData();

      if (formData) {
        this.defaultValue = formData;
      }

      this.modalConfig.visible = false;
    },
  },
};
