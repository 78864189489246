<script>
import TablePage from '@/found/components/table_page';
// import { Form, ShuttleBox } from '../form';

export default {
  extends: TablePage,
  components: {
    // Modal,
    // Form,
    // ShuttleBox,
  },
  data() {
    return {
      formConfig: {},
      params: { enableStatus: '009' },
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('select_template_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formName = 'ShuttleBox';
        this.openModal();
      }
    },
  },
};
</script>
